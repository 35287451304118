import React from 'react';
import { hydrate } from 'react-dom';

import App from './App';
import { BrowserRouter } from 'react-router-dom'

/*
const initialData = window.__INITIAL_DATA__ || {};
console.log("Initial data in hydration step: ", initialData)
hydrate(<App {...initialData}/>, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
*/
const hydrateApp = () => {
  const initialData = window.__INITIAL_DATA__ || {};
  hydrate(<BrowserRouter><App {...initialData}/></BrowserRouter>, document.getElementById('root'));
};


// Run the hydration function after the DOM content is loaded
document.addEventListener('DOMContentLoaded', hydrateApp);