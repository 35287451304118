export const gameCategories = [
    "action",
    "arcade",
    "puzzle",
    "strategy",
    "card",
    "casino",
    "dice",
    "sport",
    "board",
    "idle",
]